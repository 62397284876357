
.judge-admin{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .judge-admin-header{
    display: flex;
    justify-content: flex-end;
  }
}
.school-prompt{
  color: #999;
  font-size: 12px;
}
.logo-src-box{
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  position: relative;
  .src-box{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #EEEEEE;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  i{
    line-height: 1;
    font-size: 14px;
    color: #DCDCDC;
    position: absolute;
    right: -20px;
    top: 0;
    cursor: pointer;
  }
}
.logo-uploader{
  ::v-deep.el-upload{
    width: 80px;
    height: 80px;
    background: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    .logo-uploader-icon{
      font-size: 32px;
      color: #A0A0A0;
    }
  }
}
.column-logo-box{
  display: flex;
  align-items: center;
  justify-content: center;
  .column-logo{
    width: 48px;
    height: 48px;
    border: 1px solid #EEEEEE;
    border-radius: 50%;
    overflow: hidden;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
}
